import authFetchHandler from './tools/auth/authFetchHandler'
// const lessonContentDomain = process.env.NODE_ENV === 'development' ?
//   'http://localhost:3000' :
//   `https://${process.env.REACT_APP_LESSON_CONTENT_BASE_URI}`

// Cors error prevents the above solution.
const lessonContentDomain = `https://${process.env.REACT_APP_LESSON_CONTENT_BASE_URI}`

const firebaseDomain = process.env.NODE_ENV === 'development' ?
  `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJ_ID}/us-central1/api` :
  `https://${process.env.REACT_APP_WEB_HOST_URI}/api`

const cs1CloudFunctionDomain = 'https://us-central1-make-firialabs.cloudfunctions.net'

var errorCb = () => { }

const setErrorCb = (cb) => {
  errorCb = cb
}

const getSim = async (url, user) => {
  if (user) {
    try {
      const resp = await authFetchHandler(`${url}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (resp.status === 404) {
        errorCb()
      }
      return resp
    } catch (err) {
      console.log(err)
    }
  }
  return { status: 'logged out' }
}

const postSim = async (url, user, params) => {
  if (user) {
    try {
      const resp = await authFetchHandler(`${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      })
      if (resp.status === 404) {
        errorCb()
      }
      return resp
    } catch (err) {
      console.log(err)
    }
  }
  return { status: 'logged out' }
}

const putSim = async (url, user, params) => {
  if (user) {
    try {
      const resp = await authFetchHandler(`${url}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      })
      if (resp.status === 404) {
        errorCb()
      }
      return resp
    } catch (err) {
      console.log(err)
    }
  }
  return { status: 'logged out' }
}

const deleteSim = async (url, user) => {
  if (user) {
    try {
      const resp = await authFetchHandler(`${url}`, {
        method: 'DELETE',
      })
      if (resp.status === 404) {
        errorCb()
      }
      return resp
    } catch (err) {
      console.log(err)
    }
  }
  return { status: 'logged out' }
}

const FETCH_RESP = Object.freeze({
  ORDER_ALREADY_EXISTS: 'order already exists',
  GROUP_MEMBER_ALREADY_EXISTS: 'group member already exists',
  GROUP_ALREADY_EXISTS: 'group already exists',
  USER_ALREADY_OWNS_LICENSE: 'user already owns license',
  FAILED_TO_TRANSLATE_SKU: 'failed to translate sku',
  LICENSE_TERM_FORMAT_INVALID: 'license term format invalid',
  FAILED_USER_VALIDATION: 'failed user validation',
  ORDER_NUMBER_INVALID: 'order number invalid',
  FAILED_GETTING_EXISTING_ORDER: 'failed getting existing order',
  REQUESTING_USER_OWNS_ORDER: 'requesting user owns order',
  OTHER_USER_OWNS_ORDER: 'other user owns order',
  FAILED_GETTING_USER_DATA: 'failed getting user data',
  FAILED_GETTING_GROUP_DATA: 'failed getting group data',
  FAILED_GETTING_LICENSE_DATA: 'failed getting license data',
  FAILED_GETTING_SKU_DATA: 'failed getting sku data',
  FAILED_GETTING_MEMBER_DATA: 'failed getting member data',
  FAILED_GETTING_GROUP_DATA_FROM_JOINCODE: 'failed getting group data from joincode',
  USER_LACKS_LICENSE_PERMISSION: 'user lacks license permission',
  USER_LACKS_GROUP_PERMISSION: 'user lacks group permission',
  ERROR_ASSIGNING_LICENSES: 'error assigning licenses',
  MISSING_PARAMS: 'request is missing params',
  USER_LACKS_MEMBER_GROUPS: 'user lacks member groups',
  USER_LACKS_ADMIN_PERMISSION: 'user lacks admin permission',
  ASSIGNED_LICENSE_FULL: 'assigned license full',
  GROUP_UNJOINABLE: 'group unjoinable',
  LICENSE_DOES_NOT_EXIST: 'license does not exist',
  USER_DOES_NOT_EXIST: 'user does not exist',
  USER_REACHED_REGISTRATION_LIMIT: 'user has reached registration limit',
})

const adjustColor = (color, amount) => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
}

const AVATAR_SOURCE = '/pub/'

export { firebaseDomain, lessonContentDomain, postSim, getSim, putSim, deleteSim, FETCH_RESP, adjustColor, setErrorCb, AVATAR_SOURCE, cs1CloudFunctionDomain }